// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FourOhFour from "../pages/FourOhFour.bs.js";
import * as SessionsRoute from "./SessionsRoute.bs.js";
import * as SessionsRouter from "./SessionsRouter.bs.js";
import * as DashboardRouter from "./DashboardRouter.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function fromUrl(url) {
  var match = url.path;
  if (!match) {
    return ;
  }
  switch (match.hd) {
    case "dashboard" :
        return /* Dashboard */1;
    case "sessions" :
        return /* Sessions */0;
    default:
      return ;
  }
}

function useRouter(param) {
  return fromUrl(RescriptReactRouter.useUrl(undefined, undefined));
}

function AppRouter(Props) {
  var route = fromUrl(RescriptReactRouter.useUrl(undefined, undefined));
  React.useEffect((function () {
          if (route !== undefined) {
            
          } else {
            RescriptReactRouter.push(SessionsRoute.toString(/* Login */1));
          }
          
        }), [route]);
  if (route !== undefined) {
    if (route) {
      return React.createElement(DashboardRouter.make, {});
    } else {
      return React.createElement(SessionsRouter.make, {});
    }
  } else {
    return React.createElement(FourOhFour.make, {});
  }
}

var make = AppRouter;

export {
  fromUrl ,
  useRouter ,
  make ,
  
}
/* react Not a pure module */
