// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../Auth.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SessionsLink from "../routers/SessionsLink.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as DashboardRoute from "../routers/DashboardRoute.bs.js";
import * as MutationErrors from "../components/MutationErrors.bs.js";
import * as Hooks from "react-relay/hooks";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as LoginPageMutation_graphql from "../__generated__/LoginPageMutation_graphql.bs.js";

var make_loginInput = LoginPageMutation_graphql.Utils.make_loginInput;

var makeVariables = LoginPageMutation_graphql.Utils.makeVariables;

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: LoginPageMutation_graphql.node,
              variables: LoginPageMutation_graphql.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, LoginPageMutation_graphql.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? LoginPageMutation_graphql.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, LoginPageMutation_graphql.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = Hooks.useMutation(LoginPageMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      return Curry._2(param$1, LoginPageMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? LoginPageMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      return Curry._2(param$5, store, LoginPageMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: LoginPageMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

var LoginPageMutation_make_response_login = LoginPageMutation_graphql.Utils.make_response_login;

var LoginPageMutation_makeOptimisticResponse = LoginPageMutation_graphql.Utils.makeOptimisticResponse;

var LoginPageMutation = {
  make_loginInput: make_loginInput,
  makeVariables: makeVariables,
  make_response_login: LoginPageMutation_make_response_login,
  makeOptimisticResponse: LoginPageMutation_makeOptimisticResponse,
  Types: undefined,
  commitMutation: commitMutation,
  use: use
};

function LoginPage(Props) {
  var match = React.useState(function () {
        return {
                email: "",
                password: ""
              };
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = use(undefined);
  var login = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setErrors = match$2[1];
  var handleLogin = function (param) {
    Curry.app(login, [
          undefined,
          (function (data, errors) {
              if (errors !== undefined) {
                return Curry._1(setErrors, (function (param) {
                              return errors;
                            }));
              }
              Curry._1(setErrors, (function (param) {
                      
                    }));
              var response = data.login;
              if (response !== undefined) {
                Auth.setToken(response.token);
                return RescriptReactRouter.push(DashboardRoute.toString(/* Dashboard */0));
              } else {
                return Curry._1(setErrors, (function (param) {
                              return [{
                                        message: "Unknown error, contact support."
                                      }];
                            }));
              }
            }),
          undefined,
          undefined,
          undefined,
          undefined,
          Curry._1(makeVariables, Curry._4(make_loginInput, state.email, state.password, undefined, undefined)),
          undefined,
          undefined
        ]);
    
  };
  return React.createElement("div", {
              className: "min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
            }, React.createElement("div", {
                  className: "sm:mx-auto sm:w-full sm:max-w-md"
                }, React.createElement("img", {
                      className: "mx-auto h-12 w-auto",
                      alt: "Workflow",
                      src: "https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    }), React.createElement("h2", {
                      className: "mt-6 text-center text-3xl font-extrabold text-gray-900"
                    }, "Login to your account"), React.createElement("p", {
                      className: "mt-2 text-center text-sm text-gray-600"
                    }, "Or ", React.createElement(SessionsLink.make, {
                          route: /* Signup */0,
                          className: "font-medium text-indigo-600 hover:text-indigo-500",
                          children: "register a new account"
                        }))), React.createElement("div", {
                  className: "mt-8 sm:mx-auto sm:w-full sm:max-w-md"
                }, React.createElement("div", {
                      className: "bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
                    }, React.createElement("form", {
                          className: "space-y-6",
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              return handleLogin(undefined);
                            })
                        }, React.createElement("div", undefined, React.createElement("label", {
                                  className: "block text-sm font-medium text-gray-700",
                                  htmlFor: "email"
                                }, "Email address"), React.createElement("div", {
                                  className: "mt-1"
                                }, React.createElement("input", {
                                      className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
                                      autoComplete: "email",
                                      name: "email",
                                      placeholder: "email@example.com",
                                      required: true,
                                      type: "email",
                                      value: state.email,
                                      onChange: (function ($$event) {
                                          var value = $$event.target.value;
                                          return Curry._1(setState, (function (param) {
                                                        return {
                                                                email: value,
                                                                password: state.password
                                                              };
                                                      }));
                                        })
                                    }))), React.createElement("div", undefined, React.createElement("label", {
                                  className: "block text-sm font-medium text-gray-700",
                                  htmlFor: "password"
                                }, "Password"), React.createElement("div", {
                                  className: "mt-1"
                                }, React.createElement("input", {
                                      className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
                                      autoComplete: "current-password",
                                      name: "password",
                                      placeholder: "It's a secret",
                                      required: true,
                                      type: "password",
                                      value: state.password,
                                      onChange: (function ($$event) {
                                          var value = $$event.target.value;
                                          return Curry._1(setState, (function (param) {
                                                        return {
                                                                email: state.email,
                                                                password: value
                                                              };
                                                      }));
                                        })
                                    }))), React.createElement("div", undefined, React.createElement("button", {
                                  className: "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                  type: "submit",
                                  onClick: handleLogin
                                }, match$1[1] ? "Loading..." : "Login"), React.createElement("div", {
                                  className: "mt-4"
                                }, React.createElement(MutationErrors.make, {
                                      errors: match$2[0]
                                    })))))));
}

var make = LoginPage;

export {
  LoginPageMutation ,
  make ,
  
}
/* Auth Not a pure module */
