// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Loading(Props) {
  return React.createElement("div", {
              className: "flex items-center justify-center bg-gray-200 min-h-screen"
            }, React.createElement("div", undefined, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                      icon: ProSolidSvgIcons.faSpinnerThird,
                      className: "text-5xl animate-spin text-gray-800 opacity-75",
                      fixedWidth: true
                    }), React.createElement("div", {
                      className: "mt-4 text-lg text-gray-800 opacity-75"
                    }, "Loading...")));
}

var make = Loading;

export {
  make ,
  
}
/* react Not a pure module */
