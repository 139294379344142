// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppRouter from "./routers/AppRouter.bs.js";

function App(Props) {
  return React.createElement(AppRouter.make, {});
}

var make = App;

var $$default = App;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
