// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function FourOhFour(Props) {
  return React.createElement("div", undefined, "Uh Oh! Four oh Four");
}

var make = FourOhFour;

export {
  make ,
  
}
/* react Not a pure module */
