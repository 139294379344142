// Generated by ReScript, PLEASE EDIT WITH CARE


function fromUrl(url) {
  var match = url.path;
  if (!match) {
    return ;
  }
  var match$1 = match.tl;
  if (!match$1) {
    return ;
  }
  switch (match$1.hd) {
    case "login" :
        if (match$1.tl) {
          return ;
        } else {
          return /* Login */1;
        }
    case "pending" :
        if (match$1.tl) {
          return ;
        } else {
          return /* Pending */2;
        }
    case "signup" :
        if (match$1.tl) {
          return ;
        } else {
          return /* Signup */0;
        }
    default:
      return ;
  }
}

function toString(route) {
  switch (route) {
    case /* Signup */0 :
        return "/sessions/signup";
    case /* Login */1 :
        return "/sessions/login";
    case /* Pending */2 :
        return "/sessions/pending";
    
  }
}

export {
  fromUrl ,
  toString ,
  
}
/* No side effect */
