// Generated by ReScript, PLEASE EDIT WITH CARE


function fromUrl(url) {
  var match = url.path;
  if (!match) {
    return ;
  }
  var match$1 = match.tl;
  if (!match$1) {
    return /* Dashboard */0;
  }
  if (match$1.hd !== "campaigns") {
    return ;
  }
  var match$2 = match$1.tl;
  if (match$2 && !match$2.tl) {
    return /* Campaign */{
            id: match$2.hd
          };
  }
  
}

function toString(route) {
  if (route) {
    return "/dashboard/campaigns/" + route.id;
  } else {
    return "/dashboard";
  }
}

export {
  fromUrl ,
  toString ,
  
}
/* No side effect */
