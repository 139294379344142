// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as React from "react";
import * as Loading from "./Loading.bs.js";
import * as RelayEnv from "./RelayEnv.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as ReactExperimental from "rescript-relay/src/ReactExperimental.bs.js";

function init(param) {
  return ReactExperimental.renderConcurrentRootAtElementWithId(React.createElement(RescriptRelay.Context.Provider.make, {
                  environment: RelayEnv.environment,
                  children: React.createElement(React.Suspense, {
                        children: React.createElement(App.make, {}),
                        fallback: React.createElement(Loading.make, {})
                      })
                }), "root");
}

export {
  init ,
  
}
/* App Not a pure module */
