// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "reason-promise/src/js/promise.bs.js";
import * as RelayEnv from "../RelayEnv.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as DashboardRoute from "../routers/DashboardRoute.bs.js";
import * as Hooks from "react-relay/hooks";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as PendingApprovalPageQuery_graphql from "../__generated__/PendingApprovalPageQuery_graphql.bs.js";

function use(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(PendingApprovalPageQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(PendingApprovalPageQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(PendingApprovalPageQuery_graphql.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(PendingApprovalPageQuery_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, PendingApprovalPageQuery_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, PendingApprovalPageQuery_graphql.node, PendingApprovalPageQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: PendingApprovalPageQuery_graphql.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, PendingApprovalPageQuery_graphql.node, PendingApprovalPageQuery_graphql.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return PendingApprovalPageQuery_graphql.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, param) {
  var data = Hooks.usePreloadedQuery(PendingApprovalPageQuery_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(PendingApprovalPageQuery_graphql.Internal.convertResponse, data);
}

function retain(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(PendingApprovalPageQuery_graphql.node, PendingApprovalPageQuery_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var Query = {
  Types: undefined,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded,
  retain: retain
};

function PendingApprovalPage(Props) {
  var queryData = use(undefined, undefined, undefined, undefined, undefined);
  var handleRefresh = function (param) {
    $$fetch(RelayEnv.environment, undefined, (function (res) {
            if (res.TAG === /* Ok */0 && res._0.me.approved) {
              return RescriptReactRouter.push(DashboardRoute.toString(/* Dashboard */0));
            }
            
          }), undefined, undefined, undefined);
    
  };
  React.useEffect((function () {
          if (queryData.me.approved) {
            RescriptReactRouter.push(DashboardRoute.toString(/* Dashboard */0));
          }
          
        }), [queryData.me.approved]);
  return React.createElement("div", {
              className: "p-6"
            }, React.createElement("div", {
                  className: "text-lg text-gray-800 font-semibold text-center"
                }, "Pending Approval"), React.createElement("div", {
                  className: "mt-4 text-sm text-gray-800 text-center"
                }, "Please contact an adming to approve your account"), React.createElement("button", {
                  className: "mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                  onClick: handleRefresh
                }, "Refresh"));
}

var make = PendingApprovalPage;

export {
  Query ,
  make ,
  
}
/* react Not a pure module */
