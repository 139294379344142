// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function setToken(token) {
  localStorage.setItem("AUTH_TOKEN", token);
  
}

function getToken(param) {
  return Caml_option.null_to_opt(localStorage.getItem("AUTH_TOKEN"));
}

function logout(param) {
  localStorage.removeItem("AUTH_TOKEN");
  
}

var match = localStorage.getItem("AUTH_TOKEN");

var isLoggedIn = match !== null;

export {
  setToken ,
  getToken ,
  logout ,
  isLoggedIn ,
  
}
/* match Not a pure module */
