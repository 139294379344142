// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function MutationErrors(Props) {
  var errors = Props.errors;
  if (errors !== undefined) {
    return Belt_Array.mapWithIndex(errors, (function (i, e) {
                  return React.createElement("div", {
                              key: String(i),
                              className: "text-sm text-pink-500 mt-2 first:mt-0"
                            }, e.message);
                }));
  } else {
    return null;
  }
}

var make = MutationErrors;

export {
  make ,
  
}
/* react Not a pure module */
