// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "reason-promise/src/js/promise.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DashboardLink from "../routers/DashboardLink.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Hooks from "react-relay/hooks";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as DashboardPageQuery_graphql from "../__generated__/DashboardPageQuery_graphql.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";

var getConnectionNodes = DashboardPageQuery_graphql.Utils.getConnectionNodes;

function use(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(DashboardPageQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(DashboardPageQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(DashboardPageQuery_graphql.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(DashboardPageQuery_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, DashboardPageQuery_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, DashboardPageQuery_graphql.node, DashboardPageQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: DashboardPageQuery_graphql.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, DashboardPageQuery_graphql.node, DashboardPageQuery_graphql.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return DashboardPageQuery_graphql.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, param) {
  var data = Hooks.usePreloadedQuery(DashboardPageQuery_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(DashboardPageQuery_graphql.Internal.convertResponse, data);
}

function retain(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(DashboardPageQuery_graphql.node, DashboardPageQuery_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var Query = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded,
  retain: retain
};

function DashboardPage(Props) {
  var queryData = use(undefined, undefined, undefined, undefined, undefined);
  return React.createElement("div", {
              className: "bg-gray-100 min-h-screen"
            }, React.createElement("div", {
                  className: "p-5 bg-gray-800"
                }, React.createElement("div", {
                      className: "md:flex md:items-center md:justify-between"
                    }, React.createElement("div", {
                          className: "flex-1 min-w-0"
                        }, React.createElement("h2", {
                              className: "text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate"
                            }, "All Campaigns")))), React.createElement("div", {
                  className: "m-4"
                }, React.createElement("div", {
                      className: "bg-white shadow overflow-hidden rounded sm:rounded-md"
                    }, React.createElement("ul", {
                          className: "divide-y divide-gray-200"
                        }, Belt_Array.map(Curry._1(getConnectionNodes, queryData.me.campaigns), (function (campaign) {
                                return React.createElement("li", {
                                            key: campaign.id
                                          }, React.createElement(DashboardLink.make, {
                                                route: /* Campaign */{
                                                  id: campaign.id
                                                },
                                                className: "block hover:bg-gray-50",
                                                children: React.createElement("div", {
                                                      className: "px-4 py-4 sm:px-6"
                                                    }, React.createElement("div", {
                                                          className: "flex items-center justify-between"
                                                        }, React.createElement("p", {
                                                              className: "text-sm font-medium text-gray-800 truncate"
                                                            }, Belt_Option.getWithDefault(campaign.name, "No Title")), React.createElement("div", {
                                                              className: "ml-2 flex-shrink-0 flex"
                                                            }, React.createElement("p", {
                                                                  className: "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                                                }, campaign.vertical.name))), React.createElement("div", {
                                                          className: "mt-2 sm:flex sm:justify-between"
                                                        }, React.createElement("div", {
                                                              className: "flex"
                                                            }, React.createElement("p", {
                                                                  className: "flex items-center text-sm text-gray-500"
                                                                }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                                                                      icon: ProDuotoneSvgIcons.faUser,
                                                                      className: "flex-shrink-0 mr-1.5 text-gray-400"
                                                                    }), Belt_Option.getWithDefault(campaign.affiliate.name, "No Affiliate Name")), React.createElement("p", {
                                                                  className: "flex items-center text-sm text-gray-500 ml-6"
                                                                }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                                                                      icon: ProDuotoneSvgIcons.faIdBadge,
                                                                      className: "flex-shrink-0 mr-1.5 text-gray-400"
                                                                    }), campaign.affiliate.externalId)), React.createElement("div", {
                                                              className: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                                                            })))
                                              }));
                              }))))));
}

var make = DashboardPage;

export {
  Query ,
  make ,
  
}
/* react Not a pure module */
