// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.bs.js";
import * as React from "react";
import * as DashboardRoute from "./DashboardRoute.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function DashboardLink(Props) {
  var route = Props.route;
  var className = Props.className;
  var activeClassNameOpt = Props.activeClassName;
  var children = Props.children;
  var activeClassName = activeClassNameOpt !== undefined ? activeClassNameOpt : "";
  var $$location = DashboardRoute.toString(route);
  var activeClasses = activeClassName;
  return React.createElement("a", {
              className: Cx.cx([
                    className,
                    "cursor-pointer hover:underline",
                    activeClasses
                  ]),
              href: $$location,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return RescriptReactRouter.push($$location);
                })
            }, children);
}

var make = DashboardLink;

export {
  make ,
  
}
/* react Not a pure module */
