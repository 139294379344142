// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LoginPage from "../pages/LoginPage.bs.js";
import * as FourOhFour from "../pages/FourOhFour.bs.js";
import * as SignupPage from "../pages/SignupPage.bs.js";
import * as SessionsRoute from "./SessionsRoute.bs.js";
import * as PendingApprovalPage from "../pages/PendingApprovalPage.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function useRouter(param) {
  return SessionsRoute.fromUrl(RescriptReactRouter.useUrl(undefined, undefined));
}

function SessionsRouter(Props) {
  var route = SessionsRoute.fromUrl(RescriptReactRouter.useUrl(undefined, undefined));
  if (route === undefined) {
    return React.createElement(FourOhFour.make, {});
  }
  switch (route) {
    case /* Signup */0 :
        return React.createElement(SignupPage.make, {});
    case /* Login */1 :
        return React.createElement(LoginPage.make, {});
    case /* Pending */2 :
        return React.createElement(PendingApprovalPage.make, {});
    
  }
}

var make = SessionsRouter;

export {
  useRouter ,
  make ,
  
}
/* react Not a pure module */
