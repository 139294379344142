// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FourOhFour from "../pages/FourOhFour.bs.js";
import * as CampaignPage from "../pages/CampaignPage.bs.js";
import * as DashboardPage from "../pages/DashboardPage.bs.js";
import * as DashboardRoute from "./DashboardRoute.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function useRouter(param) {
  return DashboardRoute.fromUrl(RescriptReactRouter.useUrl(undefined, undefined));
}

function DashboardRouter(Props) {
  var route = DashboardRoute.fromUrl(RescriptReactRouter.useUrl(undefined, undefined));
  if (route !== undefined) {
    if (route) {
      return React.createElement(CampaignPage.make, {
                  id: route.id
                });
    } else {
      return React.createElement(DashboardPage.make, {});
    }
  } else {
    return React.createElement(FourOhFour.make, {});
  }
}

var make = DashboardRouter;

export {
  useRouter ,
  make ,
  
}
/* react Not a pure module */
