// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "reason-promise/src/js/promise.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DashboardLink from "../routers/DashboardLink.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Belt_SortArray from "bs-platform/lib/es6/belt_SortArray.js";
import * as ReactSortablejs from "react-sortablejs";
import * as Hooks from "react-relay/hooks";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as CampaignPageQuery_graphql from "../__generated__/CampaignPageQuery_graphql.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";
import * as CampaignPageUpdateMutation_graphql from "../__generated__/CampaignPageUpdateMutation_graphql.bs.js";

function use(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(CampaignPageQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(CampaignPageQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(CampaignPageQuery_graphql.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(CampaignPageQuery_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, CampaignPageQuery_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, CampaignPageQuery_graphql.node, CampaignPageQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: CampaignPageQuery_graphql.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, CampaignPageQuery_graphql.node, CampaignPageQuery_graphql.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return CampaignPageQuery_graphql.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, param) {
  var data = Hooks.usePreloadedQuery(CampaignPageQuery_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(CampaignPageQuery_graphql.Internal.convertResponse, data);
}

function retain(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(CampaignPageQuery_graphql.node, CampaignPageQuery_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var Query_makeVariables = CampaignPageQuery_graphql.Utils.makeVariables;

var Query = {
  makeVariables: Query_makeVariables,
  Types: undefined,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded,
  retain: retain
};

var make_updateAffiliateOfferInput = CampaignPageUpdateMutation_graphql.Utils.make_updateAffiliateOfferInput;

var makeVariables = CampaignPageUpdateMutation_graphql.Utils.makeVariables;

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: CampaignPageUpdateMutation_graphql.node,
              variables: CampaignPageUpdateMutation_graphql.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, CampaignPageUpdateMutation_graphql.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? CampaignPageUpdateMutation_graphql.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, CampaignPageUpdateMutation_graphql.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = Hooks.useMutation(CampaignPageUpdateMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      return Curry._2(param$1, CampaignPageUpdateMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? CampaignPageUpdateMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      return Curry._2(param$5, store, CampaignPageUpdateMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: CampaignPageUpdateMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

var CampaignPageUpdateMutation_make_response_updateAffiliateOffer_affiliateOffer = CampaignPageUpdateMutation_graphql.Utils.make_response_updateAffiliateOffer_affiliateOffer;

var CampaignPageUpdateMutation_make_response_updateAffiliateOffer = CampaignPageUpdateMutation_graphql.Utils.make_response_updateAffiliateOffer;

var CampaignPageUpdateMutation_makeOptimisticResponse = CampaignPageUpdateMutation_graphql.Utils.makeOptimisticResponse;

var CampaignPageUpdateMutation = {
  make_updateAffiliateOfferInput: make_updateAffiliateOfferInput,
  makeVariables: makeVariables,
  make_response_updateAffiliateOffer_affiliateOffer: CampaignPageUpdateMutation_make_response_updateAffiliateOffer_affiliateOffer,
  make_response_updateAffiliateOffer: CampaignPageUpdateMutation_make_response_updateAffiliateOffer,
  makeOptimisticResponse: CampaignPageUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  commitMutation: commitMutation,
  use: use$1
};

function CampaignPage(Props) {
  var id = Props.id;
  var queryData = use({
        id: id
      }, undefined, undefined, undefined, undefined);
  var match = use$1(undefined);
  var update = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setItems = match$1[1];
  var items = match$1[0];
  React.useEffect((function () {
          var campaign = queryData.node;
          if (campaign !== undefined) {
            var edges = campaign.affiliateOffers.edges;
            if (edges !== undefined) {
              var offers = Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.keepMap(edges, (function (e) {
                              if (e === undefined) {
                                return ;
                              }
                              var affiliateOffer = e.node;
                              if (affiliateOffer !== undefined) {
                                return affiliateOffer;
                              }
                              
                            })), (function (a, b) {
                          return a.priority - b.priority | 0;
                        })), (function (affiliateOffer) {
                      return {
                              id: affiliateOffer.id,
                              selected: false,
                              chosen: false,
                              filtered: false,
                              affiliateOffer: affiliateOffer
                            };
                    }));
              Curry._1(setItems, (function (param) {
                      return offers;
                    }));
            }
            
          }
          
        }), [queryData.node]);
  var campaign = queryData.node;
  if (campaign !== undefined) {
    return React.createElement("div", {
                className: "bg-gray-100 min-h-screen"
              }, React.createElement("div", {
                    className: "p-5 bg-gray-800"
                  }, React.createElement("div", {
                        className: "md:flex md:items-center md:justify-between"
                      }, React.createElement("div", {
                            className: "flex-1 min-w-0"
                          }, React.createElement("div", {
                                className: "flex items-center"
                              }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                                    icon: ProSolidSvgIcons.faChevronLeft,
                                    className: "text-sm text-gray-400 cursor-pointer",
                                    fixedWidth: true
                                  }), React.createElement(DashboardLink.make, {
                                    route: /* Dashboard */0,
                                    className: "text-sm text-gray-400",
                                    children: "Back"
                                  })), React.createElement("h2", {
                                className: "mt-2 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate"
                              }, Belt_Option.getWithDefault(campaign.name, "No Title"))))), React.createElement(ReactSortablejs.ReactSortable, {
                    list: items,
                    setList: (function (param) {
                        
                      }),
                    children: Belt_Array.map(items, (function (i) {
                            var image = i.affiliateOffer.offer.step1Image;
                            return React.createElement("li", {
                                        className: "p-4 flex bg-white"
                                      }, image !== undefined ? React.createElement("div", {
                                              className: "h-10 w-10 rounded-full bg-gray-200 bg-contain bg-no-repeat bg-center",
                                              style: {
                                                backgroundImage: "url(" + image + ")"
                                              }
                                            }) : React.createElement("div", {
                                              className: "h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center"
                                            }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                                                  icon: ProSolidSvgIcons.faTag,
                                                  className: "text-white",
                                                  fixedWidth: true
                                                })), React.createElement("div", {
                                            className: "ml-3 flex-1"
                                          }, React.createElement("p", {
                                                className: "text-sm font-medium text-gray-900"
                                              }, Belt_Option.getWithDefault(i.affiliateOffer.offer.name, "No Name")), React.createElement("div", {
                                                className: "flex items-center"
                                              }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                                                    icon: ProDuotoneSvgIcons.faIdBadge,
                                                    className: "flex-shrink-0 mr-2 text-gray-400 text-sm"
                                                  }), React.createElement("div", {
                                                    className: "text-sm text-gray-500"
                                                  }, i.affiliateOffer.offer.externalId))), React.createElement("div", {
                                            className: "ml-2 flex-shrink h-10 w-10 rounded border border-gray-300 bg-gray-200 flex items-center\n              justify-center cursor-move"
                                          }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                                                icon: ProSolidSvgIcons.faBars,
                                                className: "text-sm text-gray-500",
                                                fixedWidth: true
                                              })));
                          })),
                    tag: "ul",
                    animation: 200,
                    className: "divide-y divide-gray-200",
                    ghostClass: "dragging-ghost",
                    chosenClass: "dragging-chosen",
                    dragClass: "dragging-drag",
                    onEnd: (function ($$event) {
                        var match = Belt_Array.get(items, $$event.oldIndex);
                        var match$1 = Belt_Array.get(items, $$event.newIndex);
                        if (match !== undefined && match$1 !== undefined) {
                          Belt_Array.set(items, $$event.oldIndex, match$1);
                          Belt_Array.set(items, $$event.newIndex, match);
                          Curry.app(update, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (store, param) {
                                    var oldProxy = store.get(match.affiliateOffer.__id);
                                    if (!(oldProxy == null)) {
                                      oldProxy.setValue($$event.newIndex, "priority", undefined);
                                    }
                                    var newProxy = store.get(match$1.affiliateOffer.__id);
                                    if (!(newProxy == null)) {
                                      newProxy.setValue($$event.oldIndex, "priority", undefined);
                                      return ;
                                    }
                                    
                                  }),
                                Curry._1(makeVariables, Curry._4(make_updateAffiliateOfferInput, match.affiliateOffer.id, $$event.newIndex, undefined, undefined)),
                                undefined,
                                undefined
                              ]);
                          return ;
                        }
                        
                      })
                  }));
  } else {
    return "No Campaign";
  }
}

var make = CampaignPage;

export {
  Query ,
  CampaignPageUpdateMutation ,
  make ,
  
}
/* react Not a pure module */
